import React, { FC, useState } from "react";

interface IExpandableText {
  text: string;
  maxLength: number;
}

const ExpandableText: React.FC<IExpandableText> = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!text) return <span>No Data</span>;

  const formattedText = text.split("\n").join("<br />");

  const truncatedText =
    text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : formattedText;

  return (
    <div>
      <span
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{
          __html: isExpanded ? formattedText : truncatedText,
        }}
      />
      {text.length > maxLength && (
        <button
          className="bg-boundsYellow-50 text-black py-1 px-2 text-sm rounded-lg min-w-8 mt-2 ml-4"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Less" : "More"}
        </button>
      )}
    </div>
  );
};

export default ExpandableText;
