import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWater } from '@fortawesome/free-solid-svg-icons'; 
import { faPepperHot } from '@fortawesome/free-solid-svg-icons'; 
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { faHouseFloodWater } from '@fortawesome/free-solid-svg-icons';
import { faMountain } from '@fortawesome/free-solid-svg-icons'; 
import { faPlane } from '@fortawesome/free-solid-svg-icons'; 
import { faSeedling } from '@fortawesome/free-solid-svg-icons'; 
import { DataOption } from "../../../../types/insights";
import CropHistory from "../../../features/InsightsMenu/CropHistory/CropHistory";
import PropertyTax from "../../../features/InsightsMenu/PropertyTax";
import Yield from "../../../features/InsightsMenu/Yield";
import Flood from "../../../features/InsightsMenu/Flood";
import WaterWells from "../../../features/InsightsMenu/WaterWells";
import Soils from "../../../features/InsightsMenu/Soils";
import Aircraft from "../../../features/InsightsMenu/Aircraft";
import Elevation from "../../../features/InsightsMenu/Elevation";
import useBoundsUI from "../../../../hooks/useBoundsUI";

const options = [
  {
    label: "Crop history",
    Icon: faPepperHot,
    name: DataOption.CropHistory,
    Comp: <CropHistory />,
  },
  {
    label: "Property Tax",
    Icon: faChartLine,
    name: DataOption.PropertyTax,
    Comp: <PropertyTax />,
  },
  {
    label: "Yield",
    Icon: faChartPie,
    name: DataOption.Yield,
    Comp: <Yield />,
  },
  { label: "Flood", Icon: faHouseFloodWater, name: DataOption.Flood, Comp: <Flood /> },
  {
    label: "Elevation",
    Icon: faMountain,
    name: DataOption.Elevation,
    Comp: <Elevation />,
  },
  {
    label: "Water",
    Icon:  faWater,
    name: DataOption.WaterWells,
    Comp: <WaterWells />,
  },
  {
    label: "Aircraft",
    Icon:  faPlane,
    name: DataOption.Aircraft,
    Comp: <Aircraft />,
  },
  {
    label: "Soils",
    Icon:  faSeedling,
    name: DataOption.Soils,
    Comp: <Soils />,
  },
];

const Insights = () => {
  const {
    sidebarMenu: { setIsSubmenuOpen, isSubmenuOpen },
    insightData: { setInsightType, setData },
  } = useBoundsUI();
  //set -1 to show the general menu
  const [currentOption, setCurrentOption] = useState<number>(-1);

  const openSubmenu = (index: number) => {
    setCurrentOption(index);
    setInsightType(options[index].name);
    setIsSubmenuOpen(true);
  };

  useEffect(() => {
    if (!isSubmenuOpen) {
      setCurrentOption(-1);
      setInsightType(null);
      setData([]);
    }
  }, [isSubmenuOpen]);

  useEffect(() => {
    setIsSubmenuOpen(false);
  }, []);

  return (
    <div className="w-auto">
      {!isSubmenuOpen && (
        <div className="w-[218px]">
          <h1 className="text-[32px] pb-6 border-b-[1px] border-boundsYellow-50 ">
            Insights
          </h1>
          <div className="grid grid-cols-2 gap-6 mt-6 w-full">
            {options.map(({ label, Icon }, index) => (
              <div
                key={`insight-option_${index}`}
                className="flex flex-col items-center cursor-pointer gap-2"
                onClick={() => openSubmenu(index)}
              >
                <FontAwesomeIcon icon={Icon} width={24} />
                <span className="text-sm">{label}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {isSubmenuOpen && currentOption >= 0 && options[currentOption]?.Comp}
    </div>
  );
};

export default Insights;
