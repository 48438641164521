import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import HandsHelpingIcon from "../../../icons/HandsHelping.icon";
import CheckMoneyIcon from "../../../icons/CheckMoney.icon";
import LandMarkIcon from "../../../icons/LandMark.icon";
import CalendarDayIcon from "../../../icons/CalendarDay.icon";
import MoneyBillWaveIcon from "../../../icons/MoneyBillWave.icon";
import PieChartIcon from "../../../icons/PieChart.icon";
import LineChartIcon from "../../../icons/ChartLine.icon";
import BarChartIcon from "../../../icons/Chart.icon";
import { capitalizeEachWord, formatMoney } from "../../../../utils/string";
import Input from "../../../atoms/Input";
import clsx from "clsx";
import Title from "../../../atoms/Title";
import {
  getTotalMortgages,
  getUccFiled,
  getTotalLoan,
  getEstimatedInterest,
  getTotalAcreCount,
  getFarmableAcres,
  getMortgageHolders,
  getMonthlyMortgages,
} from "../../../../services/dashboard";
import { IMortgageHolder } from "../../../../types/financialOverview";
import BarChart from "../../../atoms/charts/BarChart";
import LineChart from "../../../atoms/charts/LineChart";
import PieChart from "../../../atoms/charts/PieChart";
import DashboardTable from "../DashboardTable";
import { IColumn, ISort } from "../DashboardTable/DashboardTable";
import DashboardCard from "../DashboardCard";
import { getDateOnly } from "../../../../utils/financialOverview";
import ChartCollection from "../../../atoms/charts/ChartCollection";
import useDebounce from "../../../../hooks/useDebounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedParcelLId } from "../../../../redux/actions";
import { formatDate } from "../../../../utils/date";

const FinancialOverview = ({
  time,
  startDate,
  endDate,
  loan_institution,
  county,
  state,
}: {
  time: string;
  startDate: Date | null;
  endDate: Date | null;
  loan_institution?: string[];
  county?: string[];
  state?: string;
}) => {
  const dispatch = useDispatch();

  const handleParcelLId = (parcel_lid: string) => {
    if (parcel_lid) {
      dispatch(setSelectedParcelLId(parcel_lid));
      const newTab = window.open("/", "_blank", "noopener,noreferrer");
      if (!newTab) {
        console.error("Failed to open new tab");
      }
    } else {
      console.warn("parcel_lid is missing");
    }
  };

  const columns: IColumn[] = [
    {
      field: "issue",
      sortField: "issue",
      sortable: true,
      header: "Map",
      minWidth: 60,
      additionalFields: ["parcel_lid"],
      transform: (
        val: string,
        extra?: { [key: string]: any }
      ): string | number | JSX.Element => {
        if (val) return <>Not Available</>;

        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => handleParcelLId(extra?.parcel_lid)}
          >
            <FontAwesomeIcon className="question-mark" icon={faMap} />
          </div>
        );
      },
    },
    {
      field: "type",
      sortField: "type",
      header: "Type",
      minWidth: 60,
    },
    {
      field: "date",
      header: "Date",
      transform: (val: string) => (val ? formatDate(val) : "N/A"),
    },
    {
      field: "loan_institution",
      sortField: "loan_institution",
      header: "From",
      minWidth: 135,
    },
    {
      field: "borrower",
      sortField: "borrower",
      header: "To",
      minWidth: 135,
    },
    {
      field: "description",
      header: "Description",
      minWidth: 120,
      transform: (val: string) =>
        val && val.includes("No data") ? capitalizeEachWord(val) : val,
    },
    {
      field: "county",
      sortField: "county",
      header: "County",
      minWidth: 120,
    },
    {
      field: "mortgage_amount",
      sortField: "mortgage_amount",
      header: "Mortgage",
      minWidth: 110,
      transform: (val: number) => formatMoney(val),
    },
  ];

  const abortController = new AbortController();
  const signal = abortController.signal;

  const [cardParams, setCardParams] = useState<{
    start_date: string;
    end_date: string;
    loan_institution?: string[];
    county?: string[];
    state?: string;
  }>();
  const [mortgageCount, setMortgageCount] = useState(-1);
  const [uccCount, setUccCount] = useState(-1);
  const [isLoadingLineChart, setIsLoadingLineChart] = useState(true);
  const [lineChartData, setLineChartData] = useState<{
    values: number[];
    labels: string[];
  } | null>();
  const [chartMessage, setChartMessage] = useState("");

  const [isLoadingMortgageTable, setIsLoadingMortgageTable] = useState(true);
  const [isLoadingPagination, setIsLoadingPagination] = useState(true);
  const [mortgageHolders, setMortgageHolders] = useState<IMortgageHolder[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [sort, setSort] = useState<ISort>({
    by: "row",
    order: "asc",
  });
  const debouncedSort = useDebounce(sort, 500);
  const [search, setSearch] = useState<string>();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const leftCards = useMemo(
    () => [
      {
        title: "Total Mortgages",
        icon: HandsHelpingIcon,
        fetchFunction: getTotalMortgages,
        valueField: "total_mortgages",
        valueFormat: (val: number) => val.toLocaleString(),
        callback: (val: number) => setMortgageCount(val),
      },
      {
        title: "UCC Filed",
        icon: CheckMoneyIcon,
        fetchFunction: getUccFiled,
        valueField: "ucc_filed_count",
        valueFormat: (val: number) => val.toLocaleString(),
        callback: (val: number) => setUccCount(val),
        tooltipcontent: () =>
          "Data only exists in KS. Mortgage Holder and County filters do not apply.",
      },
      {
        title: "Total Loan",
        icon: MoneyBillWaveIcon,
        fetchFunction: getTotalLoan,
        valueField: "total_loan_amount",
        valueFormat: (val: number) =>
          formatMoney(val, {
            showDecimals: false,
            useMillionAbbreviation: true,
          }),
        tooltipcontent: (val: number) =>
          formatMoney(val, { showDecimals: false }),
      },
      {
        title: "Estimated Interest",
        icon: MoneyBillWaveIcon,
        fetchFunction: getEstimatedInterest,
        valueField: "total_interest_amount",
        valueFormat: (val: number) =>
          formatMoney(val, {
            showDecimals: false,
            useMillionAbbreviation: true,
          }),
        tooltipcontent: (val: number) =>
          formatMoney(val, { showDecimals: false }),
      },
    ],
    []
  );

  const rightCards = useMemo(
    () => [
      {
        title: "Total Acre Count",
        icon: LandMarkIcon,
        fetchFunction: getTotalAcreCount,
        valueField: "total_acre_count",
        valueFormat: (val: number) => val.toLocaleString(),
      },
      {
        title: "Farmable Acres",
        icon: CalendarDayIcon,
        fetchFunction: getFarmableAcres,
        valueField: "farmable_acres_count",
        valueFormat: (val: number) => val.toLocaleString(),
      },
    ],
    []
  );

  const handleSort = (field: string) => {
    setIsLoadingMortgageTable(true);
    if (page > 1) {
      setPage(1);
    }
    if (field === sort.by) {
      setSort({
        by: field,
        order: sort.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({
        by: field,
        order: "asc",
      });
    }
  };

  const onSearch = (values: any) => {
    setSearch(values.searchTerm);
  };

  const clearSearch = () => {
    setValue("searchTerm", "");
    setSearch("");
  };

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);
  useEffect(() => {
    setIsLoadingMortgageTable(true);
    const updateMortgageHolders = async () => {
      if (startDate && endDate) {
        getMortgageHolders({
          page,
          page_size: pageSize,
          sort_by: sort.by,
          sort_order: sort.order,
          search,
          params: {
            start_date: getDateOnly(startDate),
            end_date: getDateOnly(endDate),
            loan_institution,
            county,
            state: state,
          },
          abortSignal: signal,
        }).then((response) => {
          if (!signal.aborted) {
            if (response && response.data) {
              setTotalCount(response.total_count);
              setMortgageHolders(response.data);
              setIsLoadingMortgageTable(false);
              setIsLoadingPagination(false);
            } else {
              setMortgageHolders([]);
              setIsLoadingMortgageTable(false);
              setIsLoadingPagination(false);
            }
          }
        });
      }
    };
    updateMortgageHolders();
    return () => {
      abortController.abort();
    };
  }, [
    startDate,
    endDate,
    loan_institution,
    county,
    state,
    page,
    debouncedSort,
    search,
  ]);

  useEffect(() => {
    setIsLoadingPagination(true);
  }, [startDate, endDate, loan_institution, county, search]);

  const getLineChartData = async (): Promise<any> => {
    if (startDate && endDate) {
      const today = new Date();
      const currentMonthEnd = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ); // Last day of the current month

      // Adjust endDate to not go beyond the current month
      const adjustedEndDate =
        endDate > currentMonthEnd ? currentMonthEnd : endDate;

      // If < 93 days, clear chart
      if (
        adjustedEndDate.getTime() - startDate.getTime() <
        93 * 24 * 60 * 60 * 1000
      ) {
        setLineChartData(null);
        setChartMessage("Please expand date range to get comprehensive data");
        setIsLoadingLineChart(false);
      } else {
        try {
          const response = await getMonthlyMortgages(
            {
              start_date: getDateOnly(startDate),
              end_date: getDateOnly(adjustedEndDate),
              loan_institution: loan_institution,
              county: county,
              state: state as string,
            },
            signal
          );

          if (response && response.length > 0) {
            const values = response.map((item) => item.total_mortgages);
            const labels = response.map((item) => {
              const [year, month] = item.month_year.split("-");
              return new Date(
                parseInt(year),
                parseInt(month) - 1
              ).toLocaleString("default", {
                month: "long",
                year: "numeric",
              });
            });

            return { values, labels };
          } else {
            return null;
          }
        } catch (error) {
          if (!signal.aborted) {
            return null;
          }
        } finally {
          if (!signal.aborted) {
            setIsLoadingLineChart(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    setIsLoadingLineChart(true);
    setMortgageCount(-1);
    setUccCount(-1);
    if (startDate && endDate) {
      setCardParams({
        start_date: getDateOnly(startDate),
        end_date: getDateOnly(endDate),
        loan_institution,
        county,
        state,
      });
    }
    if (page > 1) {
      setPage(1);
    }
    if (startDate && endDate) {
      getLineChartData().then((result) => {
        if (!signal.aborted) {
          if (result?.values?.length > 0) {
            setLineChartData(result);
          } else {
            setLineChartData(null);
            setChartMessage("No data available for these filters");
          }
          setIsLoadingLineChart(false);
        }
      });
    } else {
      setLineChartData({ values: [], labels: [] });
      setMortgageCount(0);
      setUccCount(0);
      setIsLoadingLineChart(false);
    }
    return () => {
      abortController.abort();
    };
  }, [startDate, endDate, loan_institution, county, state]);

  return (
    <div className={clsx("")}>
      <div className="flex w-full justify-between">
        <div className="flex w-[48%] h-full flex-wrap gap-6 justify-between">
          {leftCards.map((cardData, index) => (
            <div className="w-[46%] xl:w-[47%] " key={`card_${index}`}>
              <DashboardCard
                title={cardData.title}
                icon={cardData.icon}
                fetchFunction={cardData.fetchFunction}
                fetchParams={cardParams}
                valueField={cardData.valueField}
                percentField="change_percentage"
                timePeriod={time?.toLowerCase()}
                valueFormat={cardData.valueFormat}
                callback={cardData.callback}
                tooltipcontent={cardData.tooltipcontent}
              />
            </div>
          ))}
          <ChartCollection
            chartList={[
              {
                isLoading: mortgageCount < 0 || uccCount < 0,
                isEmpty: false,
                emptyMessage: "No data available for these filters",
                icon: (
                  <BarChartIcon width={24} height={24} cursor={"pointer"} />
                ),
                chart: (
                  <BarChart
                    labels={["Mortgages", "UCCs", "Both"]}
                    values={[mortgageCount, uccCount, mortgageCount + uccCount]}
                  />
                ),
              },
              {
                isLoading: isLoadingLineChart,
                isEmpty: !lineChartData || lineChartData.values.length < 1,
                emptyMessage: chartMessage,
                icon: (
                  <LineChartIcon width={24} height={24} cursor={"pointer"} />
                ),
                chart: (
                  <div className="h-full">
                    <div className="text-center w-full">
                      Mortgages Created per Month
                    </div>
                    <div className="h-full">
                      <LineChart
                        labels={lineChartData?.labels}
                        values={lineChartData?.values || []}
                      />
                    </div>
                  </div>
                ),
              },
              {
                isLoading: mortgageCount < 0 || uccCount < 0,
                isEmpty: false,
                emptyMessage: "No data available for these filters",
                icon: <PieChartIcon cursor={"pointer"} />,
                chart: (
                  <PieChart
                    labels={["Mortgages", "UCCs", "Both"]}
                    values={[mortgageCount, uccCount, mortgageCount + uccCount]}
                    colors={["#0263ff", "#f97223", "#8e30ff"]}
                  />
                ),
              },
            ]}
          />
        </div>

        <div className="flex w-[48%] h-full flex-wrap gap-6 justify-between">
          {rightCards.map((cardData, index) => (
            <div className="w-[46%] xl:w-[47%] " key={`card_${index}`}>
              <DashboardCard
                title={cardData.title}
                icon={cardData.icon}
                fetchFunction={cardData.fetchFunction}
                fetchParams={cardParams}
                valueField={cardData.valueField}
                percentField="change_percentage"
                timePeriod={time?.toLowerCase()}
                valueFormat={cardData.valueFormat}
              />
            </div>
          ))}
          <div className="w-full">
            <div
              className={clsx(
                "bg-boundsPurple-600 rounded-[30px] p-6 w-full border-8 border-boundsPurple-500  max-h-[425px] xl:max-h-[630px]",
                "3xl:max-h-[630px]"
              )}
            >
              <div className={clsx("flex lg:gap-1 mb-5", "justify-between")}>
                <Title label="Mortgage Holder" />
                <form onSubmit={handleSubmit(onSearch)}>
                  <div className="relative">
                    <Input
                      label={"Search"}
                      type="text"
                      {...register("searchTerm")}
                    />
                    {search && search.length > 0 && (
                      <svg
                        className="absolute right-3 bottom-3 h-1/4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={clearSearch}
                      >
                        <path
                          fill="black"
                          d="m23.666 2.683-2.35-2.35L12 9.65 2.683.333l-2.35 2.35L9.65 12 .333 21.317l2.35 2.35L12 14.35l9.316 9.317 2.35-2.35L14.35 12l9.316-9.317Z"
                        />
                      </svg>
                    )}
                  </div>
                </form>
              </div>
              <DashboardTable
                columns={columns}
                rows={mortgageHolders || []}
                total_count={totalCount || 0}
                isLoading={isLoadingMortgageTable}
                isLoadingPagination={isLoadingPagination}
                page={page}
                page_size={pageSize}
                handlePageChange={handlePageChange}
                sort={sort}
                handleSort={handleSort}
                showIndex={false}
                className="overflow-y-auto max-h-[480px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialOverview;
