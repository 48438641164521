import React, { FC, SVGProps } from "react";
import ArrowUpIcon from "../../icons/ArrowUp.icon";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

interface ICard {
  title: string;
  value: string | number;
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element; // Optional if you want to render without an icon
  percentage: number;
  comparisonPeriod: string;
  currency?: string; // Optional for currency formatting
  format?: (value: string | number) => string | number; // Optional formatting function
  onCardClick?: () => void; // Optional click handle
  isLoading?: boolean;
  errorMessage?: string;
  tooltipContent?: string;
}

const Card: FC<ICard> = ({
  title,
  value,
  Icon,
  percentage,
  comparisonPeriod,
  onCardClick,
  isLoading,
  errorMessage,
  tooltipContent,
}) => {
  return (
    <div
      className={clsx(
        "relative bg-boundsPurple-600 border-4 border-boundsPurple-500 rounded-[30px] p-3 2xl:p-6 w-full max-w-[400px] h-full max-h-[156px] ",
        "3xl:w-[400px] 3xl:h-[156px] 3xl:p-6"
      )}
    >
      <div className="flex gap-3 items-center ">
        <div className="w-5 xl:w-[26px]">
          <Icon />
        </div>
        <span className="text-md xl:text-2xl 3xl:text-2xl whitespace-nowrap text-ellipsis overflow-hidden">
          {title}
        </span>
      </div>
      {isLoading ? (
        <div className="w-full h-full flex align-center justify-center">
          <span className="flex ml-1 gap-1 items-center">
            <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse"></span>
            <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse delay-200"></span>
            <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse delay-400"></span>
          </span>
        </div>
      ) : errorMessage ? (
        <div className="w-full h-full flex align-center justify-center pt-6">
          <span className="h-fit">{errorMessage}</span>
        </div>
      ) : (
        <div className="flex  mt-1">
          <span className="text-xl xl:text-4xl 3xl:text-4xl text-center m-auto text-white">
            {value}
          </span>
          <div className="flex flex-col">
            <div
              className={clsx(
                "flex justify-center items-center gap-3 text-md xl:text-[32px] 3xl:text-[32px]",
                percentage > 0 ? "text-green-500" : "text-red-500"
              )}
            >
              {percentage.toLocaleString()}%
              {percentage == 0 ? null : (
                <div
                  className={clsx(
                    "w-3 xl:w-5",
                    percentage < 0 && "fill-red rotate-180"
                  )}
                >
                  <ArrowUpIcon className={clsx(percentage < 0 && "fill-red")} />
                </div>
              )}
            </div>
            <span className="text-xs 2xl:text-base 3xl:text-base text-center">
              vs previous {comparisonPeriod || "period"}
            </span>
          </div>
        </div>
      )}
      {tooltipContent && (
        <>
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className="question-mark"
            data-tooltip-id={`tooltip-${title}`}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              fontSize: "20px",
            }}
          />
          <Tooltip id={`tooltip-${title}`} place="bottom" className="z-10">
            {tooltipContent ? tooltipContent : ""}
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default Card;
