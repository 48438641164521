import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PopupState {
  selectedParcelLId: string | null;
}

const loadFromLocalStorage = (): PopupState => {
  const storedState = localStorage.getItem("popupState");
  return storedState ? JSON.parse(storedState) : { selectedParcelLId: null };
};

const initialState: PopupState = loadFromLocalStorage();

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setSelectedParcelLId: (state, action: PayloadAction<string | null>) => {
      state.selectedParcelLId = action.payload;

      localStorage.setItem("popupState", JSON.stringify(state));
    },
  },
});

export const { setSelectedParcelLId } = popupSlice.actions;

export default popupSlice.reducer;
