import clsx from "clsx";
import React, { useEffect } from "react";
import Pagination from "../../../molecules/Pagination";
import Skeleton from "../../../atoms/Skeleton";
import ArrowDropDownIcon from "../../../icons/ArrowDropDown";
import useBoundsUI from "../../../../hooks/useBoundsUI";

export interface IColumn {
  field: string;
  additionalFields?: string[];
  sortable?: boolean;
  sortField?: string;
  header: string;
  transform?: (
    val: any,
    extra?: { [key: string]: any }
  ) => string | number | JSX.Element;
  minWidth?: number;
  maxWidth?: number;
}

export interface ISort {
  by: string;
  order: "asc" | "desc";
}

const DashboardTable = ({
  columns,
  rows,
  total_count,
  page,
  page_size,
  handlePageChange,
  sort,
  handleSort,
  showIndex = true,
  isLoading,
  isLoadingPagination,
  className,
}: {
  columns: IColumn[];
  rows: any[];
  total_count: number;
  page: number;
  page_size: number;
  handlePageChange: (pageNumber: number) => void;
  sort?: ISort;
  handleSort?: (field: string) => void;
  showIndex?: boolean;
  isLoading: boolean;
  isLoadingPagination: boolean;
  className?: string;
}) => {
  const {
    dashboard: { setIsMortgageTableLoading },
  } = useBoundsUI();

  useEffect(() => {
    setIsMortgageTableLoading(isLoading);
  }, [isLoading]);

  return (
    <div className={clsx("mt-2", className)}>
      <table className="w-full border-b-[1px] border-boundsPurple-100 rounded-m">
        <thead className="border-b-[1px] border-boundsPurple-100">
          <tr key={"header-col"} className="bg-boundsPurple-100">
            {showIndex && (
              <th className="px-2 py-3 border-[1px] border-boundsPurple-100">
                <div className="flex justify-between items-center gap-3">#</div>
              </th>
            )}

            {columns.map((col) => (
              <th
                key={col.header}
                className="px-2 py-3 border-[1px] border-boundsPurple-100"
                style={{ minWidth: col.minWidth || 10 }}
                onClick={() => {
                  col.sortField && handleSort && handleSort(col.sortField);
                }}
              >
                <div className="flex justify-between items-center gap-3">
                  {col.header}
                  {sort && sort.by === col.sortField && (
                    <div
                      className={clsx(
                        "w-3 xl:w-5",
                        sort.order === "asc" && "rotate-180"
                      )}
                    >
                      <ArrowDropDownIcon />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-[1px] border-boundsPurple-100">
          {isLoading ? (
            <tr>
              <td colSpan={showIndex ? columns.length + 1 : columns.length}>
                <Skeleton width={"100%"} height={500} borderRadius={0} />
              </td>
            </tr>
          ) : rows && rows.length > 0 ? (
            rows ? (
              rows.map((row: any, rowIndex: number) => (
                <tr key={"row-" + rowIndex}>
                  {showIndex && (
                    <td className="p-[10px] border-t-[1px] border-t-boundsPurple-100 border-r-[1px] border-boundsPurple-100 ">
                      {page_size * (page - 1) + rowIndex + 1}
                    </td>
                  )}
                  {columns.map((col, colIndex) => {
                    const extra: { [key: string]: any } = {};
                    if (col.additionalFields) {
                      col.additionalFields.forEach((field: string) => {
                        extra[field] = row[field];
                      });
                    }
                    return (
                      <td
                        key={"row-" + rowIndex + "-col-" + colIndex}
                        className="p-[10px] border-t-[1px] border-t-boundsPurple-100 border-r-[1px] border-boundsPurple-100"
                        style={{
                          minWidth: col.minWidth || 10,
                          maxWidth: col.maxWidth || "none",
                          textOverflow: col.maxWidth ? "ellipsis" : undefined,
                        }}
                        title={col.maxWidth ? row[col.field] : undefined}
                      >
                        {(col.transform
                          ? col.transform(row[col.field], {
                              ...extra,
                              rowData: row,
                            })
                          : row[col.field]) || "No Data"}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : null
          ) : (
            <tr>
              <td colSpan={showIndex ? columns.length + 1 : columns.length}>
                <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6 text-center">
                  No results found for selected filters.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        totalCount={total_count}
        pageSize={page_size}
        onPageChange={handlePageChange}
        isLoading={isLoadingPagination}
      />
    </div>
  );
};

export default DashboardTable;
