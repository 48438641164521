import React, { useEffect } from "react";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'; 
import { Tooltip } from "react-tooltip";

const Soils = () => {
  const {
    featureLayer: { setGroup },
    insightData: { data, insightError },
  } = useBoundsUI();

  useEffect(() => {
    setGroup({
      groupName: "SoilsGroupLayer",
      layers: ["SoilsLayer"],
    });
  }, []);
  // Unmount
  useEffect(
    () => () => setGroup({ groupName: "SoilsGroupLayer", layers: null }),
    []
  );

  return (
    <div className="min-w-[436px]">
      <div className="flex flex-row items-center">
          <h2 className="text-[32px]  mr-1">Soils</h2>
          <FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
          <Tooltip className="max-w-[400px]" anchorSelect=".question-mark" place="bottom" offset={10}>
            Soil map units are the basic geographic unit of the Soil Survey Geographic Database (SSURGO). The SSURGO dataset is a compilation of soils information collected over the last century by the Natural Resources Conservation Service (NRCS)
          </Tooltip>
			</div>
      {insightError ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          {insightError}
        </p>
      ) : (
        <>
          <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
            Select a point to view data
          </p>
        </>
      )}
    </div>
  );
};

export default Soils;
